import { template as template_7699e5cc0df548f190e970e31d819abc } from "@ember/template-compiler";
const FKControlMenuContainer = template_7699e5cc0df548f190e970e31d819abc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
